<template>
    <v-container class="ma-0 pa-0">
        <v-card
            color="white"
            elevation="0"
            class="pa-3 py-5 d-inline-flex flex-row w-60"
            rounded="lg"
        >
            <div class="d-inline-flex mr-3">
                <SimpleSelect
                    :items="evaluationStateOptions ?? []"
                    :value="currentEvaluationsState"
                    :model="currentEvaluationsState"
                    :placeholder="$t('diagnoses.inputs.placeholders.chooseEvaluationState')"
                    defaultItemValueField="id"
                    defaultItemNameField="name"
                    innerIcon="mdi-note"
                    @new-value-selected="(id) => (currentEvaluationsState = id)"
                    withLabel
                    :label="$t('diagnoses.inputs.labels.state')"
                />
            </div>
            <div class="d-inline-flex mr-3">
                <SimpleSelect
                    :items="courseOptions ?? []"
                    :value="currentCourse"
                    :model="currentCourse"
                    :placeholder="$t('diagnoses.inputs.placeholders.chooseCourse')"
                    defaultItemValueField="id"
                    defaultItemNameField="name"
                    innerIcon="mdi-folder"
                    @new-value-selected="(id) => (currentCourse = id)"
                    withLabel
                    :label="$t('diagnoses.inputs.labels.course')"
                />
            </div>
            <div class="d-inline-flex mr-3">
                <SimpleSelect
                    :items="evaluationTypeOptions ?? []"
                    :value="currentEvaluationType"
                    :model="currentEvaluationType"
                    :placeholder="$t('diagnoses.inputs.placeholders.chooseEvaluationType')"
                    defaultItemValueField="id"
                    defaultItemNameField="name"
                    innerIcon="mdi-layers"
                    @new-value-selected="(id) => (currentEvaluationType = id)"
                    withLabel
                    :label="$t('diagnoses.inputs.labels.evaluation')"
                />
            </div>
        </v-card>

        <v-card class="w-100 py-6 my-5" rounded="lg" color="white" elevation="0">
            <h2 class="px-6 my-3">
                {{ $t('diagnoses.titles.assignedEvaluations') }}
            </h2>
            <v-divider></v-divider>

            <section class="pa-6">
                <AssignedEvaluationsTable
                    :headers="assignedEvaluationsHeaders"
                    :items="assignedEvaluationsItems"
                    @on-update="onUpdateEvaluation"
                    @on-stats="onStatsEvaluation"
                    @on-delete="onDeleteEvaluation"
                    showDetail
                    :loading="loading"
                />
            </section>
        </v-card>

        <!-- Modal -->
        <UpdateEvaluationModal
            :dialog="updateEvaluation"
            :isUpdate="true"
            :oldEvaluation="evaluationToUpdate"
            @close-modal="closeUpdateModal"
        />
    </v-container>
</template>

<script>
import {
 mapActions, mapGetters, mapMutations, mapState
} from 'vuex'
import SimpleSelect from '@/components/core/forms/selects/simpleSelect/index.vue'
import AssignedEvaluationsTable from '@/components/diagnoses/shared/tables/assignedEvaluations/index.vue'
import UpdateEvaluationModal from '@/components/diagnoses/shared/modals/assignEvaluation.vue'
import * as Sentry from '@sentry/vue'
import { diagnosticAssignmentService } from '@/services/evaluations/diagnosticAssignment'

export default {
    name: 'AssignedEvaluations',
    components: {
        SimpleSelect,
        AssignedEvaluationsTable,
        UpdateEvaluationModal,
    },
    data() {
        return {
            currentEvaluationsState: 0,
            currentCourse: 0,
            currentEvaluationType: 0,
            updateEvaluation: false,
            successUpdate: false,
            selectedEvaluation: undefined,
            loading: false,
        }
    },
    computed: {
        ...mapState({
            courses: (state) => state.userData.courses,
            userID: (state) => state.userData.user.id,
            diagnosesFilters: (state) => state.diagnoses.filters,
        }),
        ...mapGetters({
            evaluations: 'diagnoses/assignedEvaluations',
        }),
        assignedEvaluationsHeaders() {
            return [
                {
                    text: this.$t('diagnoses.tables.assignedEvaluations.labels.evaluation'),
                    align: 'start',
                    sortable: false,
                    value: 'levelName',
                },
                {
                    text: this.$t('diagnoses.tables.assignedEvaluations.labels.state'),
                    align: 'center',
                    sortable: false,
                    value: 'evaluationProgress',
                    width: '150px',
                },
                {
                    text: this.$t('diagnoses.tables.assignedEvaluations.labels.course'),
                    align: 'center',
                    sortable: false,
                    value: 'course',
                },
                {
                    text: this.$t('diagnoses.tables.assignedEvaluations.labels.startDate'),
                    align: 'center',
                    sortable: false,
                    value: 'startDate',
                },
                {
                    text: this.$t('diagnoses.tables.assignedEvaluations.labels.assignmentType'),
                    align: 'center',
                    sortable: false,
                    value: 'assignmentType',
                },
                {
                    text: this.$t('diagnoses.tables.assignedEvaluations.labels.actions'),
                    align: 'center',
                    sortable: false,
                    value: 'id',
                },
            ]
        },
        assignedEvaluationsItems() {
            let filteredEvaluations = this.evaluations

            if (this.currentCourse !== 0) {
                filteredEvaluations = filteredEvaluations.filter(
                    (evaluation) => evaluation.course.id === this.currentCourse
                )
            }

            if (this.currentEvaluationType === 'ALL') {
                filteredEvaluations = filteredEvaluations.filter(
                    (evaluation) => evaluation.level
                )
            } else {
                filteredEvaluations = filteredEvaluations.filter(
                    (evaluation) => evaluation.level === this.currentEvaluationType
                )
            }

            if (this.currentEvaluationsState !== 0) {
                filteredEvaluations = filteredEvaluations.filter((evaluation) => (this.currentEvaluationsState === 'ACTIVE'
                        ? evaluation.active
                        : !evaluation.active))
            }

            if (!filteredEvaluations.length) {
                return []
            }

            return filteredEvaluations.map((e) => ({
                ...e,
                evaluationProgress: e.progress,
                levelName: this.evaluationTypeOptions?.find((option) => option?.id === e?.level)
                    ?.name,
            }))
        },
        evaluationStateOptions() {
            return [
                {
                    id: 'ACTIVE',
                    name: this.$t('diagnoses.inputs.filters.diagnosticState.active'),
                },
                {
                    id: 'INACTIVE',
                    name: this.$t('diagnoses.inputs.filters.diagnosticState.inactive'),
                },
            ]
        },
        evaluationTypeOptions() {
            return [
                {
                    id: 'ALL',
                    name: this.$t('diagnoses.inputs.filters.evaluationType.all'),
                },
                {
                    id: 'INITIAL',
                    name: this.$t('diagnoses.inputs.filters.evaluationType.initial'),
                },
                {
                    id: 'INTERMEDIATE',
                    name: this.$t('diagnoses.inputs.filters.evaluationType.middle'),
                },
                {
                    id: 'ADVANCED',
                    name: this.$t('diagnoses.inputs.filters.evaluationType.final'),
                },
            ]
        },
        evaluationToUpdate() {
            return this.selectedEvaluation
        },
        courseOptions() {
            const courseList = this.courses
            .filter((course) => course.grade !== 'G0')
            .sort((a, b) => {
                if (a.ordinalGrade < b.ordinalGrade) return -1
                if (a.ordinalGrade > b.ordinalGrade) return 1

                    // grades are equal, compare by section
                    if (a.section < b.section) return -1
                    if (a.section > b.section) return 1
                    return 0
            })

            // courseList.unshift({
            //     id: 0,
            //     name: this.$t('diagnoses.inputs.filters.utils.all'),
            // })

            return courseList
        },
    },
    methods: {
        ...mapMutations('diagnoses', [
            'setCourseSelected',
            'setDiagnosticEvaluationType',
            'setDiagnosticEvaluationState',
        ]),
        ...mapActions('diagnoses', ['getAssignedEvaluations']),
        setSelectedFilters() {
            if (this.diagnosesFilters.currentCourseID) {
                this.currentCourse = this.diagnosesFilters.currentCourseID
            } else {
                this.currentCourse = this.courseOptions[0].id
            }
            if (this.diagnosesFilters.currentEvaluationType) {
                this.currentEvaluationType = this.diagnosesFilters.currentEvaluationType
            } else {
                this.currentEvaluationType = this.evaluationTypeOptions[0].id
            }
            if (this.diagnosesFilters.evaluationState) {
                this.currentEvaluationsState = this.diagnosesFilters.evaluationState
            } else {
                this.currentEvaluationsState = this.evaluationStateOptions[0].id
            }
        },
        async getEvaluations() {
            await this.getAssignedEvaluations({ teacherID: this.userID })
        },
        async onUpdateEvaluation(evaluation) {
            this.selectedEvaluation = evaluation
            this.updateEvaluation = true
        },
        onStatsEvaluation(evaluation) {
            this.$router.push(`/teacher/diagnoses/results?evaluationID=${evaluation.id}`)
        },
        async closeUpdateModal(updateList) {
            this.updateEvaluation = false
            this.selectedEvaluation = null
            if (updateList) {
                this.loading = true
                await this.getEvaluations()
                this.loading = false
            }
        },
        async onDeleteEvaluation(evaluation) {
            this.loading = true
            try {
                await diagnosticAssignmentService.deleteDiagnosis(evaluation.id)
            } catch (error) {
                Sentry.captureException(error)
                let message = this.$t(`diagnoses.errors.codes.${error}`)
                if (!message || !error) {
                    message = this.$t('diagnoses.errors.codes.default')
                }
                this.$modal.error(message)
            }
            await this.getEvaluations()
            this.loading = false
        },
    },
    watch: {
        currentCourse(courseID) {
            this.setCourseSelected({ courseID })
        },
        currentEvaluationsState(evaluationState) {
            this.setDiagnosticEvaluationState({ evaluationState })
        },
        currentEvaluationType(evaluationType) {
            this.setDiagnosticEvaluationType({ evaluationType })
        },
    },
    async beforeMount() {
        this.setSelectedFilters()

        this.loading = true
        await this.getEvaluations()
        this.loading = false
    },
}
</script>
